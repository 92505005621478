<template>
  <!--background Area Start-->
  <div class="overlay-dark-fullwidth_">
    <div class="backgrount-area pt-110 pb-110 overlay-dark-fullwidth_">
      <div class="banner-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="text-content-wrapper text-center full-width">
                <div class="text-content text-center">
                  <h1
                    class="title1 cd-headline push text-center border-none mt-40"
                  >
                    <span class="cd-words-wrapper">
                      <b class="is-visible"> 404 </b>
                    </span>
                  </h1>
                  <h3
                    class="title1 cd-headline push text-center border-none mt-40"
                  >
                    <span class="cd-words-wrapper">
                      <b class="is-visible"> {{ devlope }} </b>
                    </span>
                  </h3>
                  <div class="banner-readmore">
                    <router-link
                      href="#"
                      aria-label="title"
                      to="/"
                      class="button-default button-green"
                      >{{ home }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of background Area-->
</template>

<script>
export default {
  data() {
    return {
      lang: "",
      //translate
      home: "",
      devlope: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    document.getElementById("VueMainJsNewTouch")?.remove();
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "/js/main.js");
    recaptchaScript.id = "VueMainJsNewTouch";
    document.head.appendChild(recaptchaScript);
    if (localStorage.getItem("lang") == "Ar") {
      self.home = "الرئيسية";
      self.devlope = "الصفحة غير موجودة";
    } else {
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "./style-ltr.css");
      document.head.appendChild(link1);
      self.home = "Home";
      self.devlope = "Not found page 404";
    }
    document
      .getElementById("YG_U")
      .setAttribute("style", "display:none !important");
  },
};
</script>

<style scoped>
.overlay-dark-fullwidth_ {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999999999;
  background: #fff;
}
.banner-content h1 {
  font-size: 200px;
}
.banner-content h3 {
  font-size: 50px;
}
.banner-content h1,
.banner-content h3 {
  color: #3498db;
}
.banner-readmore {
  margin-top: 50px;
}
.banner-readmore .button-default.button-green:hover {
  background: #3498db;
  color: #fff;
}
</style>
